import React, { useMemo } from 'react';
import {Link, useParams} from "react-router-dom";
import {FaChevronCircleLeft, FaChevronCircleRight} from 'react-icons/fa';
import ContentScreen from "../ContentScreen/ContentScreen";
import {Cities, IVenue} from "btdt-types";
import venuesDataProvider from "../data/venuesDataProvider";
import VenuesTimeline from "../ExploreScreen/common/VenueDetails/VenuesTimeline";
import './YearInReviewScreen.css';

const CITY_NAMES: Record<Cities, string> = {
    [Cities.TLV]: 'תל אביב-יפו',
    [Cities.HAIFA]: 'חיפה',
};

export default function YearInReviewScreen(): React.ReactElement {
    const { year: yearParam, city: cityParam } = useParams();

    const year = parseInt(yearParam as string);
    const city = cityParam as Cities;

    const hasNextYear = year < new Date().getFullYear();

    const cityName = CITY_NAMES[city];

    const { venues } = venuesDataProvider.useSearchVenuesQuery({ city, yearRange: { from: year, to: year } });

    const { venuesOpened, venuesClosed } = useMemo(() => {
        const venuesOpened: IVenue[] = [];
        const venuesClosed: IVenue[] = [];
        venues.forEach((venue) => {
           if (venue.yearOpened === year && !venue.isYearOpenedEstimate) {
               venuesOpened.push(venue);
           }
           if (venue.yearClosed === year && !venue.isYearClosedEstimate) {
               venuesClosed.push(venue);
           }
        });
        return { venuesOpened, venuesClosed };
    }, [year, city, venues]);

    return (
        <ContentScreen metaTitle={`סיכום שנת ${year} ב${cityName}`}>
            <div className="YearInReview-navigator">
                <Link to={`/${city}/year-in-review/${year - 1}`} className="prev">
                    <FaChevronCircleRight size={32} />
                </Link>
                <h1>{`${cityName}. שנת ${year}.`}</h1>
                {hasNextYear && (
                    <Link to={`/${city}/year-in-review/${year + 1}`} className="next">
                        <FaChevronCircleLeft size={32} />
                    </Link>
                )}
            </div>
            <div className="YearInReviewScreen-columns">
                <div>
                    <h3>נסגרו</h3>
                    <VenuesTimeline venues={venuesClosed} currentVenueId={0} showAddress />
                </div>
                <div>
                    <h3>נפתחו</h3>
                    <VenuesTimeline venues={venuesOpened} currentVenueId={0} showAddress />
                </div>
            </div>
        </ContentScreen>
    )
}